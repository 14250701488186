<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <el-form-item label="医生团队：">
        <el-select v-model="formInline.teamCode">
          <el-option
            v-for="item in doctorAllTeamList"
            :key="item.teamCode"
            :value="item.teamCode"
            :label="item.teamName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时间类型：">
        <el-select v-model="formInline.timeType" filterable>
          <el-option
            v-for="item in timeTypeList"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-form-item>
          <el-button
            v-if="$checkPermission('ADDSOURCE', $route)"
            type="primary"
            @click="addData()"
          >
            新增
          </el-button>
          <el-button type="primary" @click="queryClear()"> 重置 </el-button>
        </el-form-item>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="teamName" label="医生团队" align="center" />
      <el-table-column prop="timeType" label="时间类型" align="center">
        <template slot-scope="scope">
          {{ timeTypeDic[scope.row.timeType] }}
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="时间段" align="center">
        <template slot-scope="scope">
          <!-- {{ dateFormat(scope.row) }} -->
          {{ scope.row.startTime }}-{{ scope.row.endTime }}
        </template>
      </el-table-column>
      <el-table-column prop="sourceNum" label="号源" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('EDITSOURCE', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog :title="titleType" :visible.sync="dialogVisible" width="40%">
      <el-form
        v-if="dialogVisible"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        @submit.native.prevent
      >
        <el-form-item label="医生团队:" prop="teamCode">
          <el-select v-model="form.teamCode" :disabled="isEditor">
            <el-option
              v-for="item in doctorTeamList"
              :key="item.teamCode"
              :value="item.teamCode"
              :label="item.teamName"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="时间类型" prop="timeType">
          <el-select
            v-model="form.timeType"
            filterable
            @change="refreshTimeScope"
          >
            <el-option
              v-for="item in timeList"
              :key="item.value"
              :value="item.value"
              :label="item.text"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="时间段" prop="startTime">
          <div class="timeScope">
            <el-time-select
              v-model="form.startTime"
              style="width: 30%; margin-right: 10px"
              :picker-options="timeScope"
              placeholder="选择时间"
            />
            <div>-</div>
            <el-time-select
              v-model="form.endTime"
              style="width: 30%; margin-left: 10px"
              placeholder="选择时间"
              disabled
            />
          </div>
        </el-form-item>
        <el-form-item label="号源数:" prop="sourceNum">
          <el-input
            v-model="form.sourceNum"
            v-check-permission
            placeholder="请输入号源数"
            style="width: 40%"
            maxlength="20"
            @keyup.enter.native="certification(false)"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveData()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sourceNumRule } from '@/utils/verificationRule.js';
import { timeTypeList, timeTypeDic } from '@/utils/component/publicList.js';
import moment from 'moment';
export default {
  name: 'SourceModel',
  data() {
    this.timeList = [
      { text: '上午', value: 'MORNING' },
      { text: '下午', value: 'AFTERNOON' },
      { text: '晚上', value: 'NIGHT' },
    ];
    return {
      timeTypeList: timeTypeList, // 时间类型列表
      timeTypeDic: timeTypeDic, // 时间类型字典
      dialogVisible: false, // 控制修改弹框显示与否
      titleType: '',
      loading: false,
      formInline: {
        timeType: 'all',
        teamCode: 'all',
      },
      timeScope: {
        start: '08:00',
        step: '00:30',
        end: '12:00',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      rules: {
        sourceNum: [
          { required: false, validator: sourceNumRule, trigger: 'blur' },
        ],
        teamCode: [
          { required: true, message: '请选择团队', trigger: 'change' },
        ],
        timeType: [
          { required: true, message: '请选择时间类型', trigger: 'change' },
        ],
        startTime: [
          { required: true, message: '请选择时间段', trigger: 'change' },
        ],
      },
      form: {
        timeType: '',
        teamCode: '',
        teamName: '',
        startTime: '',
        endTime: '',
        sourceNum: '5',
      },
      editId: '', // 编辑号源数据的id
      orderListData: [], // 服务列表
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      occupationList: (state) => state.occupationList,
      professionalList: (state) => state.professionalList,
    }),
    ...mapState('doctorTeam', {
      doctorTeamList: (state) => state.allDoctorList,
    }),
    isEditor() {
      return this.titleType === '修改号源';
    },
    doctorAllTeamList() {
      var list = JSON.parse(JSON.stringify(this.doctorTeamList));
      list.unshift({
        teamCode: 'all',
        teamName: '全部',
      });
      return list;
    },
  },
  watch: {
    'form.startTime': function (value) {
      if (value) {
        this.form.endTime = this.getTime(value);
      }
    },
    'form.teamCode': function (value) {
      this.doctorTeamList.forEach((ele) => {
        if (ele.teamCode === value) {
          this.form.teamName = ele.teamName;
        }
      });
    },
    'form.timeType': function (value) {
      this.timeCope(value);
    },
  },
  mounted() {
    this.getTeamList();
    this.getSourceList();
  },
  methods: {
    ...mapActions('basicDictionary', [
      'occupationQueryList',
      'professionalQueryList',
    ]),
    ...mapActions('doctorManage', [
      'consultationPageQuery',
      'consultationGet',
      'consultationEdit',
    ]),
    ...mapActions('doctorTeam', ['queryList']),
    // 时间时间段方法
    timeCope(value) {
      switch (value) {
        case 'MORNING':
          this.getTimeScope('00:00', '11:30');
          break;
        case 'AFTERNOON':
          this.getTimeScope('12:00', '18:30');
          break;
        default:
          this.getTimeScope('19:00', '23:30');
      }
    },
    // 时间类型切换时时间段值更新
    refreshTimeScope(value) {
      this.form.startTime = '';
      this.form.endTime = '';
      this.timeCope(value);
    },
    // dateFormat(scope) {
    //   var timeScope = moment(scope.startTime).format('YY')
    //   return timeScope

    // },
    // 查询团队列表
    getTeamList() {
      this.queryList({});
    },
    // 时间段方法
    getTimeScope(start, end) {
      this.timeScope = {
        start: start,
        step: '00:30',
        end: end,
      };
    },
    // 30分钟后的时间
    getTime(time) {
      const curData = +new Date(`2020-01-01 ${time}:00`);
      let laterDate = curData + 30 * 60 * 1000;
      if (time === '23:30') {
        laterDate -= 1000;
      }
      return moment(laterDate).format('HH:mm');
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getSourceList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getSourceList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        timeType: 'all',
        teamCode: 'all',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getSourceList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getSourceList();
    },
    // 新增号源模板
    addData() {
      this.titleType = '新增号源';
      this.dialogVisible = true;
      this.form = {
        timeType: '',
        teamCode: '',
        startTime: '',
        teamName: '',
        endTime: '',
        sourceNum: '5',
      };
    },
    // 分页查询问诊类型列表
    getSourceList() {
      this.loading = true;
      const { pageInfo, formInline } = this;
      const params = {
        timeType: formInline.timeType === 'all' ? '' : formInline.timeType,
        teamCode: formInline.teamCode === 'all' ? '' : formInline.teamCode,
        page: {
          done: true,
          pageIndex: pageInfo.page,
          pageSize: pageInfo.size,
        },
      };
      this.$api
        .sourcePageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    /**
     * 列表中数据的操作方法
     * 1 - 编辑
     */
    dataOperation(item) {
      this.dialogVisible = true;
      this.titleType = '修改号源';
      this.editId = item.id;
      this.form = {
        startTime: item.startTime,
        endTime: item.endTime,
        timeType: item.timeType,
        teamCode: item.teamCode,
        sourceNum: item.sourceNum,
        teamName: item.teamName,
      };
    },
    // 新增或者编辑号源
    saveData() {
      const { form, isEditor, editId } = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const param = {
            ...form,
            businessType: 'VIDEO',
          };
          if (isEditor) {
            param.id = editId;
          }
          this.$api.sourceEdit(param).then((res) => {
            if (res.code === 0) {
              this.dialogVisible = false;
              this.$message.success(isEditor ? '修改号源成功' : '新增号源成功');
              this.getSourceList();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.timeScope {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
9
